/**
 * Author: Hong Wang
 * Github: https://github.com/H0NGWANG
**/

h2 {
  font-size: 20px;
  font-weight: 500;
  color: var(--global-theme-color);
}

.left-side {
  padding: 25px;
}

.cv-profile {
  text-align: center;
  padding-bottom: 15px;
}

.btn-cv {
  padding: 10px;
  color: white;
  background-color: var(--global-theme-color);
  width: 100%;
  border-radius: 0px;
}

.btn-cv:hover {
  color: #fff;
  background-color: var(--global-theme-color);
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none;
  color: white;
}

.cv-social {
  padding: 0;
  list-style-type: none;
}

.cv-social i {
  width: 20px;
}

.right-side {
  padding: 0px;
}

section {
  padding: 40px;
  border-bottom: 1px solid #ddd;
}

.section-title {
  text-transform: uppercase;
  margin-left: -5px;
}

.upper-row {
  position: relative;
}

.company, .school-name{
  color: var(--global-theme-color);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
}

.time {
  font-size: 15px;
  font-weight: 500;
  color:  var(--global-theme-color);
}

.job-title, .school-title {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 5px;
}

.job li, section {
  font-size: 15px;
}

.honors h3{
  color:  var(--global-theme-color);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
}


footer {
  text-align: center;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .right-side {
      border-top: 1px solid #ddd;
  }
}

@media (min-width: 992px) {
  .left-side {
      border-right: 1px solid #ddd;
  }

  .time {
      position: absolute;
      right: 0;
      top: 0;
  }

  .cv-header {
      /* fixed sidebar */
      position: -webkit-sticky;
      position: sticky;
      top: 130px;
  }
}
